<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Old Flock Report</h2>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-3">
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="7">
						<b-row>
							<b-col md="5">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="from_date"
								/>
							</b-col>
							<b-col md="5">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="oldFlockListReport()" variant="primary "> Filter </b-button>
							</b-col>
						</b-row>
					</b-col>
					<b-col cols="12" md="5 p-0" class="px-2">
						<b-row>
							<b-col cols="10 ">
								<b-form-group label="Flock Id">
									<v-select
										v-model="selected_flock_id"
										:options="flock_id"
										label="flock_id"
										:searchable="true"
										:clearable="false"
										@input="getParticularOldReport()"
									/>
								</b-form-group>
							</b-col>
							<b-col cols="2" class="d-flex justify-content-center align-items-center mt-1">
								<b-button @click="excelDownload()" variant="warning">
									<feather-icon icon="DownloadIcon" size="16" />
								</b-button>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</div>
			<div v-if="getOldReport && getOldReport.length > 0">
				<b-table
					:items="getOldReport"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ data.index }}
						</div>
					</template>
				</b-table>
				<!-- <div class="mt-2 mb-1">
					<b-pagination
						:value="getOldReport.current_page"
						:total-rows="getOldReport.total"
						:per-page="getOldReport.per_page"
						align="right"
						@change="oldFlockListReport"
					>
					</b-pagination>
				</div> -->
			</div>
			<div v-else>
				<b-row class="report-view">
					<b-col md="12" class="d-flex justify-content-center flex-column align-items-center">
						<div class="circle">
							<b-img
								:src="require('@/assets/images/svg/reports/internal-report.svg')"
								style="width: 70px; height: 70px"
							/>
						</div>
						<h3 class="mt-5 ml-2 textInfo">No Old Flock Report</h3>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import vSelect from "vue-select";

export default {
	components: {
		vSelect,
	},
	data() {
		return {
			// fields: [
			// 	{
			// 		key: "date",
			// 		label: "Date",
			// 		formatter: (value) => {
			// 			return moment(value).format("MMMM Do YYYY");
			// 		},
			// 	},
			// 	{ key: "index", label: "Age" },
			// 	{ key: "total_birds", label: "Total Birds", formatter: (value) => (value === null ? 0 : value) },
			// 	{ key: "alive_birds", label: "Balance Birds", formatter: (value) => (value === null ? 0 : value) },
			// 	{ key: "mortality_rate", label: "Mortality %", formatter: (value) => (value === null ? 0 : value) },
			// 	{
			// 		key: "feed_consumption",
			// 		label: "Feed Issued",
			// 		formatter: (value) => (value === null ? 0 : value),
			// 	},
			// 	{
			// 		key: "total_feed_consumption",
			// 		label: "Total Feed",
			// 		formatter: (value) => (value === null ? 0 : value),
			// 	},
			// 	{ key: "chick_sold", label: "Sold Bird", formatter: (value) => (value === null ? 0 : value) },
			// 	{ key: "water", label: "Water Consumption", formatter: (value) => (value === null ? 0 : value) },
			// ],
			fields: [], // Initialize fields array
			productionType: null,
			flock_id: [],
			from_date: null,
			selected_flock_id: null,
			source: [],
			to_date: null,
			fromDate: null,
			toDate: null,
			getOldReport: {},
			search_filter: {
				input_field: "",
			},
		};
	},
	created: function () {
		this.fromDate = moment().clone().startOf("month").format("YYYY-MM-DD");
		this.toDate = moment().clone().endOf("month").format("YYYY-MM-DD");
		this.oldFlockListReport()
			.then((response) => {
				this.flock_id = response.data.flock_list;
				const productionTypeId = response.data.flock_list[0].production_type_id;
				// Extract production type from the response
				this.productionType = productionTypeId;

				// Generate fields based on production type
				this.fields = this.generateFields(this.productionType);
			})
			.catch((error) => {
				console.error("Error fetching production type:", error);
			});
		this.getParticularOldReport();
	},

	methods: {
		moment() {
			return moment();
		},
		oldFlockListReport(pageNo = 1) {
			let url = "";
			const farmId = this.$route.params.farmId;
			const reportType = "old";
			if (this.from_date != null || this.to_date != null) {
				url = `web/farm/${farmId}/flock-list/${reportType}?from_date=${this.from_date}&to_date=${this.to_date}`;
			} else {
				url = `web/farm/${farmId}/flock-list/${reportType}?from_date=${this.fromDate}&to_date=${this.toDate}`;
			}
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			return axiosIns.get(this.addQueryString(url, queryParams));

			// axiosIns
			// 	.get(this.addQueryString(url, queryParams))
			// 	.then((response) => {
			// 		this.flock_id = response.data.flock_list;
			// 	})
			// 	.catch((error) => {
			// 		this.$refs.setErrors(error.response.data.error);
			// 	});
		},
		calculateIndex(index) {
			return index + 1;
		},
		getParticularOldReport() {
			if (this.selected_flock_id != null) {
				const flockId = this.selected_flock_id.flock_id;
				let url = `web/flock/${flockId}/old-flock`;
				axiosIns
					.get(url)
					.then((response) => {
						this.getOldReport = response.data;
					})
					.catch((error) => {
						this.$refs.setErrors(error.response.data.message);
						error.response;
						const data = error.response.data.message;
						console.log(data, "data");
						data, "data";
						let arr = Object.values(data).flat().toString(",");
						arr, "array";
						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "danger",
								text: arr,
							},
						});
					});
			} else {
				this.oldFlockListReport();
			}
		},

		excelDownload() {
			const reportType = "old";
			let flock = this.selected_flock_id.flock_id;
			axiosIns
				.post(`web/export-report/${reportType}`, { flockId: flock })
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.getParticularOldReport();
				});
		},

		generateFields(productionType) {
			let fields = [];

			// Common fields for all production types
			const commonFields = [
				{ key: "date", label: "Date", formatter: (value) => moment(value).format("MMMM Do YYYY") },
				{ key: "index", label: "Age" },
				{ key: "total_birds", label: "Total Birds", formatter: (value) => (value === null ? 0 : value) },
				{ key: "alive_birds", label: "Alive Birds", formatter: (value) => (value === null ? 0 : value) },
				{ key: "mortality_rate", label: "Mortality %", formatter: (value) => (value === null ? 0 : value) },
				{ key: "total_feed_consumption", label: "Total Feed", formatter: (value) => (value === null ? 0 : value) },
			];

			// Add additional fields based on the production type
			switch (productionType) {
				case 1:
					fields = [
						...commonFields,
						{ key: "feed_consumption", label: "Feed Issued", formatter: (value) => (value === null ? 0 : value) },
						{ key: "chick_sold", label: "Sold Bird", formatter: (value) => (value === null ? 0 : value) },
						{ key: "water", label: "Water Consumption", formatter: (value) => (value === null ? 0 : value) },
					];
					break;
				case 2:
					fields = [
						...commonFields,
						{ key: "hdep", label: "HDEP %", formatter: (value) => (value === null ? 0 : value) },
						{ key: "hhep", label: "HHEP %", formatter: (value) => (value === null ? 0 : value) },
						{ key: "egg_weight", label: "Egg Weight", formatter: (value) => (value === null ? 0 : value) },
						{ key: "egg_produced", label: "Egg Production", formatter: (value) => (value === null ? 0 : value) },
						{ key: "body_weight", label: "weight", formatter: (value) => (value === null ? 0 : value) },
					];
					break;
				case 3:
					fields = [
						...commonFields,
						{ key: "hdep", label: "HDEP %", formatter: (value) => (value === null ? 0 : value) },
						{ key: "hhep", label: "HHEP %", formatter: (value) => (value === null ? 0 : value) },
						{ key: "egg_weight", label: "Egg Weight", formatter: (value) => (value === null ? 0 : value) },
						{ key: "egg_produced", label: "Egg Production", formatter: (value) => (value === null ? 0 : value) },
						{ key: "body_weight", label: "weight", formatter: (value) => (value === null ? 0 : value) },
					];
					break;
				// Add more cases as needed for other production types
				default:
					fields = commonFields;
			}
			return fields;
		},
	},
};
</script>

<style lang="scss" scoped>
.itemText {
	color: black;
	font-weight: 400;
}

.itemHeading {
	color: #296db4;
	font-weight: 400;
	font-size: 14px;
}

.report-view {
	background-color: #f7f7f7;
	border-radius: 5px;
	padding: 2rem 2rem;
	margin: 3rem 3rem;
	height: 400px;
}

.circle {
	width: 195px;
	height: 195px;
	border-radius: 50%;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 2px solid #296db4;
	opacity: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.textInfo {
	color: rgba(41, 109, 180, 1);
	font-size: 20px;
	font-weight: 600;
}

.underline li {
	font-size: 15px;
	list-style-type: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
